.tdtitle {
  cursor: pointer;
}

.tdtitle:hover {
  color: #f6a75c;
}

.table .handle_inner span:last-of-type {
  color: #888888;
}

.table .handle_inner span:last-of-type:hover {
  color: #f6a75c;
}